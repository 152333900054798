.Main {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: openSans;
    box-sizing: border-box;
}

.Title {
    width: 100%;
    text-align: center;
    font-family: openSans-extra-bold;
    font-size: 2.2rem;
    color: #00a99d;
    margin: 50px auto;
    box-sizing: border-box;
}