.Kecako {
    width: 100%;
    padding: 30px 15px;
    background-color: #1c1a2b;
    box-sizing: border-box;
}

.Item {
    width: 100%;
    margin: 80px auto;
    box-sizing: border-box;
}

.Left, .Right {
    width: 100%;
}

.ItemText {
    color: #fff;
    margin-bottom: 20px;
    font-size: 1.1rem;
    text-align: justify;
}

.Right {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    box-sizing: border-box;
}

.ParOrdonnance, .ParNom {
    height: 100%;
    width: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-position: center;
}

.ParOrdonnance {
    background-image: url('../assets/imgs/par-ordonnance.svg');
}

.ParNom {
    background-image: url('../assets/imgs/par-nom.svg');
}


@media(min-width: 600px){
    .Item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .Left {
        width: 50%;
        padding-left: 40px;
        box-sizing: border-box;
    }
    
    .ItemText {
        font-size: 1.5rem;
    }

    .Right {
        width: 50%;
        height: 400px;
        margin-top: 0;
        margin-left: 20px;
    }
}