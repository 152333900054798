.Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 25px;
    width: 100%;
    background-color: #1c1a2b;
    color: white;
    box-sizing: border-box;
}

.Logo {
    height: 70px;
    margin: 15px auto;
}

.FlexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}