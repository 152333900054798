.About {
    width: 100%;
    padding: 30px 15px;
    background-color: #3f3d56;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.ItemText {
    color: #fff;
    margin-bottom: 20px;
    text-align: justify;
}

ul {
    text-align: start;
}

.Youtube {
    width: 560px;
    height: 315px;
}

.videoContainer {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(min-width: 600px){
    .ItemText {
        max-width: 900px;
        font-size: 1.2rem;
    }
}

@media(max-width: 700px){
    .Youtube {
        width: 250px;
        height: 140px;
    }
}