.Fonctionalities {
    width: 100%;
    padding: 30px 15px;
    box-sizing: border-box;
}

.Item {
    width: 100%;
    margin: 80px auto;
    box-sizing: border-box;
}

.Left, .Right {
    width: 100%;
}

.ItemTitle {
    width: 100%;
    margin-bottom: 10px;
    font-size: 1.7rem;
    color: #3f3d56;
    font-family: openSans-bold;
}

.ItemText {
    color: #3f3d56;
    margin-bottom: 20px;
}

.Localisation, .Paiement, .Livraison, .Assurance {
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.Localisation {
    background-image: url('../assets/imgs/localisation.svg');
}

.Paiement {
    background-image: url('../assets/imgs/achat.svg');
}

.Livraison {
    background-image: url('../assets/imgs/livraison.svg');
}

.Assurance {
    background-image: url('../assets/imgs/assurance.svg');
}

@media(min-width: 600px){
    .Item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .Left {
        width: 40%;
        padding-left: 40px;
    }

    .ItemTitle {
        font-size: 2.0rem;
    }
    
    .ItemText {
        font-size: 1.2rem;
    }

    .Localisation, .Paiement, .Livraison, .Assurance {
        width: 60%;
        height: 300px;
        margin-right: 40px;
        background-position: right;
    }
}

