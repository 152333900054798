.Header {
    width: 100%;
    padding: 0;
    background-color: #3F3D56;
    background-image: url('../assets/imgs/wave.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.Left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    padding-left: 15px;
    margin-bottom: 70px;
    box-sizing: border-box;
}

.Title {
    width: 100%;
    text-align: start;
    margin-top: 50px;
    font-family: openSans-extra-bold;
    color: #00a99d;
    font-size: 2.0rem;
    box-sizing: border-box;

}

.SubTitle {
    width: 90%;
    text-align: start;
    margin-top: 20px;
    margin-left: 5px;
    color: #fff;
    box-sizing: border-box;
}

.Right {
    width: 100%;
    height: 300px;
    background-image: url('../assets/imgs/bonus.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.StoresContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}

.ImageContainer {
    width: 100%;
    height: 70px;
    margin-top: 15px;
    cursor: pointer;
}

.Store {
    height: 100%;
}

@media(min-width: 800px){
    .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 50px;
        box-sizing: border-box;
    }

    .Left {
        width: 65%;
        height: 600px;
        margin-bottom: 0;
        justify-content: center;
        padding-left: 50px;
    }

    .Title {
        margin-top: 0;
        font-size: 4.0rem;
    }

    .SubTitle {
        font-size: 1.5rem;
        margin-top: 50px;
    }    

    .StoresContainer {
        flex-direction: row;
        justify-content: flex-start;
    }
    
    .ImageContainer {
        width: auto;
        cursor: pointer;
    }

    .Store {
        margin-right: 20px;
    }

    .Right {
        width: 35%;
        height: 600px;
        background-image: url('../assets/imgs/head-image.svg');
    }
}