.NavBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 75px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: #3f3d56;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.26);
    box-sizing: border-box;
}

.LogoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.Logo {
    height: 50px;
}

@media(min-width: 800px){
    .LogoContainer {
        margin-left: 35px;
    }
}